<template>
  <div class="container">
    <GeneralTable
      ref="GeneralTable"
      :column="column" tableName="partsRetrievalBackstageList"
      :option="option"
      :dataList="dataList"
      :totalNum="totalNum" @search-change="getList"
      @export-data="exportData"
      @add-change="(operateTitle = '添加'), (editData = {}), (isAddVisible = true)"
      @import-click="isImport = true"
    >
      <template slot="content">
        <div class="report-box">
          <div class="report-box-item" v-for="item,index in itemList" :key="index">
            <div class="report-box-item-label">{{ item.label }}</div>
            <div class="report-box-item-value">{{ reportObj[item.key] || '--' }}</div>
          </div>
        </div>
      </template>
      <template slot="projectId">
          <el-select filterable clearable v-model="ruleForm.project" placeholder="请选择" @change="selectMachineryProductType($event, 'project')">
            <el-option v-for="item,index in userProjectList"
                    :key="index"
                    :label="item.projectName"
                    :value="index"></el-option>
          </el-select>
      </template>
      <template slot="account">
          <el-select filterable clearable v-model="ruleForm.account" placeholder="请选择" @change="selectMachineryProductType($event, 'account')">
            <el-option v-for="item,index in accountList"
                    :key="index"
                    :label="item.customerName"
                    :value="index"></el-option>
          </el-select>
      </template>
    </GeneralTable>
  </div>
</template>
<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import { reportSummaryData, downloadGenerateReport } from "@/api/basicData"
import { userProject, projectCustomer } from "@/api/SystemData";
import Template from '../RiskDecrement/riskHomeManagement/components/template.vue';
import dayjs from 'dayjs';
export default {
  name: "riskControlReport",
  components: { GeneralTable},
  data() {
    Template
    return {
      dataList: [],
      totalNum: 0,
      option: {
        isExport: true,
        isRefresh: true, // 刷新 @search-change
        isSearch: true,
        disFuzzySearch: true,
        isSelection: false,
        isTable: false,
        pageSize: 10,
        searchList: [
          {
            label: "设备名称",
            prop: "plate",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "客户名称",
            prop: "account",
            formType: "range-input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "项目名称",
            prop: "projectId",
            formType: "range-input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "处理时间",
            prop: "createTime",
            format: "yyyy.MM.dd",
            formType: "daterange",
            clearable: true,
            prop: ["begin", "end"],
          },
        ],
      },
      column: [],
      ruleForm: {
        type: "",
        project: '',
        projectId: '',
        projectName: '',
        account: '',
        accountName: '',
        accountId: '',
      },
      typesOfConstructionMachineryList: [],
      brandInfoPageList: [],
      partBrandList: [],
      deviceTypeList: [],
      isExpansion: false,
      isImport: false,
      isAddVisible: false,
      editData: {},
      oldSearchData: {},
      operateTitle: '添加',
      machineryEquipmentTypeList: [],
      machineryProductTypeList: [],
      itemList: [
        {
          label: '车辆总数',
          key: 'vehicleCount'
        },
        {
          label: '事故数量',
          key: 'accidentCount'
        },
        {
          label: '总运行时长(小时)',
          key: 'workTime'
        },
        {
          label: '驾驶员数',
          key: 'driverCount'
        },
        {
          label: '总GPS里程数(km)',
          key: 'gpsMileageTotal'
        },
        {
          label: '运输最远车辆',
          key: 'mileageMaxVehicle'
        },
        {
          label: '运输最短车辆',
          key: 'mileageMinVehicle'
        },
        {
          label: '风险车辆',
          key: 'riskVehicleCount'
        },
        {
          label: '毫米波制动次数',
          key: 'hmbBrakeCount'
        },
        {
          label: 'AEB制动次数(自动刹车)',
          key: 'aebBrakeCount'
        },
        {
          label: 'LDW(车道偏离)次数',
          key: 'ldwCount'
        },
        {
          label: 'HMW(车距过近)次数',
          key: 'hmwCount'
        },
      ],
      reportObj: {},
      userProjectList: [],
      accountList: []
    };
  },
  created() {
    this.init();
  },
  computed: {},
  methods: {
    async init() {
      let res = await userProject({
        userId: this.$store.state.userInfo.MJUserId,
      });
      this.projectLoading = false;
      if (res && res.data && res.data.length > 0) {
        this.userProjectList = res.data;
        // this.getProjectUser(res.data[0])
      }
    },
    getList(v, pageNum, pageSize, n, type){
      if (type == 'initialization') {
        return false;
      }
      if (type == 'reset') {
        this.ruleForm = {
          type: "",
          project: '',
          projectId: '',
          projectName: '',
          account: '',
          accountName: '',
          accountId: '',
        }
        return false;
      }
      if (!this.ruleForm.projectName) {
        this.$message.error('请选择项目')
        return false;
      }
      if (!this.ruleForm.accountName) {
        this.$message.error('请选择客户')
        return false;
      }
      if (!v.begin) {
        this.$message.error('请选择日期')
        return false;
      }
      if (dayjs(v.begin).add(1, 'month') < dayjs(v.end)) {
        this.$message.error('日期范围不能超过一个月')
        return false;
      }
      
      this.oldSearchData = Object.assign({...this.ruleForm} ,{...v})
      reportSummaryData(this.oldSearchData, this.ruleForm.type).then(res=>{
        this.reportObj = res.data;
      })
    },
    changeGetList() {
      this.$refs.GeneralTable.getList();
    },
    exportData(e) {
      if (!this.ruleForm.projectName) {
        this.$message.error('请选择项目')
        return false;
      }
      if (!this.ruleForm.accountName) {
        this.$message.error('请选择客户')
        return false;
      }
      if (!this.oldSearchData.begin) {
        this.$message.error('请选择日期')
        return false;
      }
      downloadGenerateReport({
        ...this.oldSearchData,
      },this.ruleForm.type);
    },
    // 选择产品类型
    selectMachineryProductType(e, type) {
      switch (type) {
        case 'project':
          this.getProjectUser(this.userProjectList[e]);
          break;
        case 'account':
          this.ruleForm.accountName = this.accountList[e].customerName;
          this.ruleForm.accountId = this.accountList[e].customerUserId;
          // this.changeGetList();
          break;
      }
    },
    async getProjectUser(e) {
      try {
        this.ruleForm.projectId = e.projectId;
        this.ruleForm.projectName = e.projectName;
        this.ruleForm.type = e.isScProject ? 2 : 1;

        let res = await projectCustomer({ projectId: e.projectId });
        if (res && res.data && res.data.length > 0) {
          this.accountList = res.data;
        } else {
          this.accountList = [];
        }
      } catch (error) {
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
  background-color: #fff;
}
.report-box {
  display: flex;
  flex-wrap: wrap;
  &-item {
    width: 200px;
    margin-left: 16px;
    margin-top: 10px;
    padding: 12px 10px 12px 12px;
    background-color: #fff;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #dee3e6;
    &-label {
      text-align: center;
      font-weight: bold;
        font-size: 16px;
        color: #333333;
        margin-bottom: 8px;
    }
    &-value {
      text-align: center;
        font-size: 14px;
        color: #666;
    }
  }
  &-item:hover{
    background: #f8fbff;
    border: 1px solid #3198ff;
  }
}
</style>
